<template>
	<div class="ysm-order-eleven">
		<div class="left" align="left">
			<img src="../../assets/ysmimg/ysmorderoneimg.png" class="infoimg" width="90px" height="110px"
				style="vertical-align: middle;">
			<div>
				<h3>完成</h3>
				<div align="left" style="margin-bottom: 0.625rem;">请接受以下条款提交订单</div>
				
			</div>
		</div>


		<!-- 右侧 -->
		<div class="right" align="left">
			<el-checkbox-group v-model="checkList">
			    <el-checkbox class="abc1" label="我确认所有给定数据的正确性。"></el-checkbox>
			    <el-checkbox class="abc2" label="我接受FORESTADENT-Bernhard Förster GmbH的隐私政策"></el-checkbox>
			    <el-checkbox class="abc3" label="我接受FORESTADENT-Bernhard Förster GmbH的使用条款"></el-checkbox>
			  </el-checkbox-group>
			  <div style="text-align: right;margin-top: 3.125rem;padding-left: 30.75rem;">
			  <el-button @click="goOrderThree" class="back"  type="button">返回</el-button>
			  <el-button @click="nextStepOrder" class="nextStep" type="button">下一步</el-button>
			  </div>
		</div>

	</div>
</template>

<script>
	export default {
		data: function() {
			return {
				checkList:[]
			}
		},
		methods: {
			//下一步
			nextStepOrder() {
				this.ruleForm.addressId=this.value;
				if(this.ruleForm.addressId==""){
					this.$alert("请选择收货地址","提示");
					return;
				}
				var abc1=document.getElementsByClassName("abc1")[0].checked;
				var abc2=document.getElementsByClassName("abc2")[0].checked;
				var abc3=document.getElementsByClassName("abc3")[0].checked;
				
				if(abc1==false||abc2==false||abc3==false){
					this.$alert("请勾选协议","提示");
					return;
				}
				this.$axios.post("/client/order/addYsmPlan", JSON.stringify(this.yaobj), {
					headers: {
						"Content-type": "application/json"
					}
				}).then(res => {
					if (res.data.code == 200) {
						this.$message.error("添加成功");
						// this.$store.commit("setYsmStepIndex", 3);
						// this.$router.push("/ysmmain/ysmorder/ysmorderthree");
					}

				}).catch(err => {
					this.$message.error("添加失败");
				})
				//this.$router.push("/ysmmain/ysmorder/ysmorderthree");
			},
			goOrderThree(){
				this.$store.commit("setYsmStepIndex", 10);
				this.$router.push("/ysmmain/ysmorder/ysmorderten");
			}
			
		},
		mounted: function() {
			this.$store.commit('setStepIndex', 11);
			//设置默认地址
			sessionStorage.setItem("location", "/ysmmain/ysmorder/ysmordernine")
			
			//获取当前添加的订单oid
			//获取oid
			// let oid=sessionStorage.getItem("oid");
			// if(oid==null){
			// 	this.$alert("请填写患者信息","提示");
			// 	this.$router.push("/addorder/orderone");
			// }else{
			// 	this.ruleForm.oid=oid;
			// }
			//设置返回路径
			this.$store.commit("setBackLocation", "/ysmmain/ysmorder/ysmorderten");
		},
		created: function() {
			//如果是编辑订单要获取oid的值
			let oid = sessionStorage.getItem("oid");
// 			this.ruleForm.doctorId = JSON.parse(sessionStorage.getItem("loginInfo")).id;
// console.log(this.ruleForm.doctorId);
			if (oid != "undefined" && oid != '' && oid != null) {
				// this.ruleForm.oid = oid;
				
			}
		},
	}
</script>

<style scoped="scoped">
	.ysm-order-eleven {
		width: 100%;
		display: flex;
		margin: 0px auto;
		margin-top: 3.125rem;
		padding-bottom: 5.125rem;
	}

	h3 {
		font-family: 'themixitalic';
		font-weight: 400;
		line-height: 1.1;
		color: #af9f73;
		margin-bottom: 20px;
		font-size: 24px;
	}

	.left {
		display: flex;
		margin-right: 12.5rem;
		position: relative;
		left: 12.5rem;
		width: 34.25rem;
	}

	/* 按钮 */
	.nextStep {
		background-color: #af9f73 !important;
		border-color: #af9f73 !important;
		color: #fff !important;
		min-width: 7.5rem;
	}

	.back {
		min-width: 7.5rem;
		background-color: transparent;
		border-color: #af9f73 !important;
		color: #af9f73 !important;
	}

	.hzinfo span {
		font-size: 0.9375rem;
		display: inline-block;
		margin-left: 1.25rem;
	}

	.infoimg {
		width: 5.625rem;
		height: 6.875rem;
	}


	img {
		border: 0;
		width: 2.3125rem;
		height: 2.3125rem;
	}
	.shname{
		display: inline-block;
		width: 8.5rem;
	}
	.shphone{
		display: inline-block;
		width: 8.5rem;
	}
</style>
